import React from "react";
import { Container, Row, Col} from "react-bootstrap";
function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
       <Row><Col md={12}>
        <p>
        <h3>ArouteSoft Infotech</h3>
        <h5><strong>Contact: 8851682339, info@aroutesoft.com</strong></h5>
        </p>
        </Col></Row>
      </Container>
    </Container>
  );
}
export default Home2;
