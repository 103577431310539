import React from "react";
import { Container, Row } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

import "./about.css";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
       <Row></Row>
      </Container>
      <ScrollToTop />
    </Container>
  );
}

export default About;
